<template>
    <article class="card-dashboard-activity">
        <div class="card-dashboard-activity__details">
            <NuxtLink
                no-prefetch
                v-if="info.properties.type === 'forum'"
                :to="{ name: 'dashboard-user', params: { user: info.properties.user.id } }"
                class="card-dashboard-activity__image"
            >
                <MiscUserPic :user="info.properties.user" class="h-16 w-16 lg:h-20 lg:w-20" />
            </NuxtLink>
            <template v-else>
                <NuxtLink
                    no-prefetch
                    v-if="linkUrl.at(0) === '/' && info.properties.model.with_details"
                    :to="linkUrl"
                    rel="follow"
                    class="card-dashboard-activity__image"
                >
                    <picture>
                        <img
                            v-if="srcImage"
                            :src="srcImage"
                            :alt="info.properties.model.title"
                            :title="info.properties.model.title"
                            width="96"
                            height="96"
                            @error="() => (showDef = true)"
                        />
                        <img v-else :src="defImage" alt="activity" />
                    </picture>
                </NuxtLink>
                <a
                    v-else
                    :href="linkUrl"
                    rel="nofollow"
                    class="card-dashboard-activity__image"
                    target="_blank"
                >
                    <picture>
                        <img
                            v-if="srcImage"
                            :src="srcImage"
                            :alt="info.properties.model.title"
                            :title="info.properties.model.title"
                            width="96"
                            height="96"
                            @error="() => (showDef = true)"
                        />
                        <img v-else :src="defImage" alt="activity" />
                    </picture>
                </a>
            </template>
            <div class="flex-grow space-y-2">
                <h3 class="card-dashboard-activity__title">
                    <span v-if="info.properties.user">{{ info.properties.user.username }}</span
                    >&ensp;<span v-html="info.description"></span>&ensp;<NuxtLink
                        no-prefetch
                        v-if="linkUrl.at(0) === '/' && info.properties.model.with_details"
                        :to="linkUrl"
                        rel="follow"
                        >{{ info.properties.model.title }}</NuxtLink
                    >&ensp;<a v-else :href="linkUrl" rel="nofollow" target="_blank">{{
                        info.properties.model.title
                    }}</a>
                </h3>
                <div class="card-dashboard-activity__foot">
                    <img :src="$assets.gray.clock" alt="icon" width="12" height="12" />
                    <p class="card-dashboard-activity__lapsed">
                        {{ $lang.components.cardDashboardActivity.time_ago }} {{ timestamp }}
                    </p>
                    <template v-if="linkUrl">
                        <NuxtLink
                            no-prefetch
                            v-if="linkUrl.at(0) === '/' && info.properties.model.with_details"
                            :to="linkUrl"
                            rel="follow"
                            class="card-dashboard-activity__button desktop"
                        >
                            {{ buttonText }}
                        </NuxtLink>
                        <a
                            v-else
                            :href="linkUrl"
                            rel="nofollow"
                            class="card-dashboard-activity__button desktop"
                            target="_blank"
                        >
                            {{ buttonText }}
                        </a>
                    </template>
                </div>
            </div>
        </div>
        <button class="card-dashboard-activity__button mobile" type="button">
            {{ buttonText }}
        </button>
    </article>
</template>

<script lang="ts">
import { formatDistanceToNowStrict, parseISO } from 'date-fns'
import { es as locale } from 'date-fns/locale'
import type { Models } from '~/types/models'

export default defineComponent({
    name: 'CardDashboardActivity',
    props: {
        info: {
            type: Object as PropType<Models.Activity>,
            required: true,
        },
    },
    data() {
        const timestamp = formatDistanceToNowStrict(parseISO(this.info.created_at || ''), { locale }).split(
            ' ',
        )

        return {
            showDef: false,
            timestamp: timestamp[0] + ' ' + timestamp[1]?.charAt(0),
            buttonMap: {
                forum: {
                    text: this.$lang.components.cardDashboardActivity.see_forum,
                    linkPrefix: `/${this.$lang.routes.forum}/`,
                },
                offer: {
                    text: this.$lang.components.cardDashboardActivity.see_offer,
                    linkPrefix: `/${this.$lang.routes.offers}/`,
                },
                coupon: {
                    text: this.$lang.components.cardDashboardActivity.see_coupon,
                    linkPrefix: `/${this.$lang.routes.coupons}/`,
                },
            },
        }
    },
    computed: {
        defImage(): string {
            return this.info.properties.type === 'offer'
                ? this.$assets.illustration.dashOffer
                : this.info.properties.type === 'coupon'
                  ? this.$assets.primary.dashCoupon
                  : this.$assets.illustration.dashGift
        },
        srcImage(): string {
            return this.showDef ? this.defImage : this.info.properties.model.image || ''
        },
        buttonText(): string {
            return (
                this.buttonMap[this.info.properties.type]?.text ||
                this.$lang.components.cardDashboardActivity.unsupported
            )
        },
        linkUrl(): string {
            return this.info.properties.model.with_details
                ? this.info.properties.model.url_front ||
                      this.$lang.components.cardDashboardActivity.unsupported
                : this.info.properties.model.url
        },
    },
})
</script>

<style lang="postcss" scoped>
.card-dashboard-activity {
    @apply bg-white p-4;
    &__image {
        @apply h-16 w-16 flex-none rounded-lg bg-white lg:h-20 lg:w-20;
        img {
            @apply h-full w-full object-contain pr-2;
        }
    }
    &__details {
        @apply flex items-center space-x-2;
    }
    &__title {
        @apply text-sm font-medium;
        span:nth-child(1) {
            @apply font-semibold text-[#1B1B1B];
        }
        span:nth-child(2) {
            @apply font-normal lowercase text-[#1B1B1B];
        }
        a {
            @apply inline font-semibold text-site-primary;
        }
    }
    &__foot {
        @apply flex w-full items-center space-x-2;
        img {
            @apply h-4 w-4;
        }
    }
    &__lapsed {
        @apply text-xs text-[#94949B];
    }
    &__button {
        @apply rounded-full border border-site-primary px-5 py-1 text-sm font-semibold text-site-primary transition duration-200 lg:hover:bg-site-primary lg:hover:text-white;
        &.desktop {
            @apply hidden md:block;
        }
        &.mobile {
            @apply mt-2 w-full md:hidden;
        }
    }
}
</style>
